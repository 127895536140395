'use client';

import clsx from 'clsx';
import { getImageProps } from 'next/image';
import React from 'react';
import Caption from './Caption';

interface ImageLoaderProps {
  src: string;
  width?: number;
  quality?: number;
  [key: string]: any; // For other props that might be passed
}

type ImageDimension = {
  height: number;
  width: number;
};

export type AspectRatios =
  | 'aspect-auto'
  | 'aspect-video'
  | 'aspect-square'
  | 'aspect-imax'
  | 'aspect-crt'
  | 'aspect-superwide'
  | string;

export interface ImageProps {
  // Required
  src: string;

  // Optional
  alt?: string;

  dimensions?: ImageDimension;
  fullWidth?: boolean;
  fullHeight?: boolean;
  cover?: boolean;
  aspect?: AspectRatios;
  className?: string;
  imgClassName?: string;
  priority?: boolean;
  quality?: number;
  format?: 'webp' | 'png';
  unoptimized?: boolean;
  blur?: boolean;
  blurData?: string;
  caption?: boolean;
  itemProp?: boolean;
}

export const ImageWrapper: React.FunctionComponent<ImageProps> = ({
  src,
  alt,
  fullWidth,
  fullHeight,
  cover,
  aspect,
  dimensions = { width: 1420, height: 1080 },
  quality = 70,
  className,
  imgClassName,
  priority,
  blur,
  blurData,
  unoptimized,
  caption,
  itemProp,
}) => {
  // Break out if there is no src to do anything with

  if (!src || src === undefined || src === null || typeof src !== 'string') {
    return null;
  }

  let cleanUrl;

  // Remove any funny params from the src URL
  if (src.startsWith('/')) {
    cleanUrl = src;
  } else {
    cleanUrl = new URL(src);
    cleanUrl.searchParams.delete('w');
    cleanUrl.searchParams.delete('h');
    cleanUrl.searchParams.delete('q');
    cleanUrl.searchParams.delete('rect');
    cleanUrl.searchParams.delete('rot');
  }

  src = cleanUrl.toString();

  const ImageClasses = clsx(
    'Image not-prose flex overflow-hidden relative',
    {
      'w-full': fullWidth,
      'h-full': fullHeight,
    },
    aspect,
    className
  );


  const commonImageProps = { alt: alt || "Image", width: dimensions.width, height: dimensions.height, quality, priority, unoptimized, blurData, blur, format: 'webp', itemProp:itemProp && 'image' };
  
  // Looks crazy but we need to account for Next Loader setting sizes for 1x and 2x pixel density's, so /3.5 for mobile to get half 1x and 2x is the width we want
  const {
    props: { srcSet: mobile },
  } = getImageProps({ ...commonImageProps, width: Math.floor(dimensions.width / 4), src: src});
  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({ ...commonImageProps, width: Math.floor(dimensions.width / 1.5), src: src});


  if(unoptimized){
    return <div className={ImageClasses}><img {...rest}  className={clsx(
      'w-full duration-500 ease-out',
      imgClassName,
      cover && 'h-full object-cover object-center',
      !cover && 'object-contain'
    )}  />
    </div>
  }
 
  return (
    <span>
      <picture className={ImageClasses}>
        <source media="(max-width: 1023px)" srcSet={mobile} />  
        <source media="(min-width: 1024px)" srcSet={desktop} />
        <img {...rest}  className={clsx(
          'w-full duration-500 ease-out',
          imgClassName,
          cover && 'h-full object-cover object-center',
          !cover && 'object-contain'
        )}  />
      </picture>
      {caption && alt && <Caption description={alt} overlay />}
    </span>
  );
};
export default ImageWrapper;
